* {
    box-sizing: border-box;
}

.container {
    border: 3px solid #f1f1f1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.row {
    padding: 10px;
    background: #f1f1f1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.column {
    float: left;
}

.left {
    width: 15%;
}

.right {
    width: 10%;
}

.middle {
    width: 75%;
}

.row:after {
    content: '';
    display: table;
    clear: both;
}

.dot {
    height: 12px;
    width: 12px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin: 4px;
}

.close {
    background: #ed594a;
}

.min {
    background: #fdd800;
}

.max {
    background: #5ac05a;
}

.searchBar {
    width: 100%;
    border-radius: 3px;
    border: none;
    background-color: white;
    margin-top: -8px;
    height: 25px;
    color: #666;
    padding: 5px;
}

.bar {
    width: 17px;
    height: 3px;
    background-color: #aaa;
    margin: 3px 0;
    display: block;
}

.content {
    padding: 10px;
}
